import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@fundo/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Inject an script given url or plain content, script's execution type and the element id where the element should be injected
   * @param content url/plain content
   * @param executionType 'async' | 'defer'
   * @param elementId target element's id to inject the script
   */
  loadScript(
    id: string,
    content: string,
    executionType: 'async' | 'defer',
    elementId: string = 'scripts',
  ) {
    const script: HTMLScriptElement = this.document.createElement('script');
    script.type = 'text/javascript';

    script.id = id;
    script.async = executionType === 'async';
    script.defer = executionType === 'defer';

    if (/^(http|https):\/\//.test(content)) {
      script.src = content;
    } else {
      script.innerHTML = content;
    }

    this.document.getElementById(elementId)?.appendChild(script);
  }

  lazyLoadScripts() {
    this.loadScript(
      'ze-snippet',
      'https://static.zdassets.com/ekr/snippet.js?key=6c707791-c5b6-4104-9239-489ed7310c78',
      'defer',
    );

    this.loadScript('script-hotjar', this.getHotjarScript(), 'defer');
  }

  loadGoogleAnalyticsScript() {
    if (environment.googleId) {
      this.loadScript(
        'script-google-analytics',
        this.getGoogleAnalyticsScript(environment.googleId),
        'defer',
      );
    }
  }

  loadGodaddyScript() {
    this.loadScript(
      'script-godaddy',
      'https://seal.godaddy.com/getSeal?sealID=8YqtlqFUGkFqNh6WwWVimLuV85e3UuwPiDo8jpcJV3bcUypLLZXxgyZZA8UC',
      'defer',
      'siteseal',
    );
  }

  loadSegmentScript() {
    const script: HTMLScriptElement = this.document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = this.getSegmentAnalyticsScript(environment.segmentApiKey);
    this.document.getElementById('head')?.appendChild(script);
  }

  private getHotjarScript(): string {
    return `
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 1935888, hjsv: 6 };
      // a = o.getElementsByTagName('body')[0];
      a = o.getElementById('scripts');
      r = o.createElement('script');
      r.defer = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    `;
  }

  private getBBBScript(): string {
    return `
    var bbbprotocol =
      'https:' == document.location.protocol ? 'https://' : 'http://';
    (function () {
      var s = document.createElement('script');
      s.src =
        bbbprotocol +
        'seal-seflorida.bbb.org' +
        unescape('%2Flogo%2Ffundo-90575447.js');
      s.type = 'text/javascript';
      s.defer = true;
      var st = document.getElementsByTagName('script');
      st = st[st.length - 1];
      var pt = st.parentNode;
      pt.insertBefore(s, pt.nextSibling);
    })();
    `;
  }

  private getGoogleAnalyticsScript(googleId: string): string {
    return `
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date();
      a = s.createElement(o),
      // m = s.getElementsByTagName(o)[0];
      m = s.getElementById('scripts');
      a.defer = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    ga('create', '${googleId}', 'auto');// add your tracking ID here.
    ga('send', 'pageview');
        `;
  }

  private getSegmentAnalyticsScript(writeKey: string): string {
    return `
      !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${writeKey}";;analytics.SNIPPET_VERSION="5.2.0";
      analytics.load("${writeKey}");
      analytics.page();
      }}();`
  }

}
