import { Route, Routes } from '@angular/router';
import { environment } from '@fundo/environments/environment';
import { SeoLandingPage } from '@shared/enums/seo-landing-page.enum';
import { DefaultTemplateComponent } from '@ui/templates/default-template/default-template.component';

import { defaultTemplateStaticPages, formTemplateStaticPages, PageConfig } from './constants/pages';
import { StatusGuard } from './guards/status.guard';

const routesDefaultTemplateStaticPages: Route = {
  path: '',
  component: DefaultTemplateComponent,
  data: {
    showNavigation: true,
  },
  children: defaultTemplateStaticPages.map((pageConfig: PageConfig) => ({
    path: pageConfig.id,
    data: {
      pageConfig,
    },
    loadChildren: () =>
      import('./new-pages/static-page/static-page.module').then(
        (m) => m.StaticPageModule,
      ),
  })),
};

const routesFormTemplateStaticPages: Route = {
  path: '',
  component: DefaultTemplateComponent,
  data: {
    showNavigation: false,
  },
  children: formTemplateStaticPages.map((pageConfig: PageConfig) => ({
    path: pageConfig.id,
    data: {
      pageConfig: pageConfig,
    },
    canActivateChild: [StatusGuard],
    loadChildren: () =>
      import('./new-pages/static-page/static-page.module').then(
        (m) => m.StaticPageModule,
      ),
  })),
};

const routesFormTemplatePages: Route = {
  path: '',
  component: DefaultTemplateComponent,
  children: [
    {
      path: 'unsubscribe',
      loadChildren: () =>
        import('./new-pages/marketing-subscription-page/routes').then(
          (mod) => mod.MARKETING_SUBSCRIPTION_PAGE_ROUTES,
        ),
    },
    {
      path: 'new-lead',
      loadChildren: () =>
        import('./new-pages/confirmation-info-page/routes').then(
          (m) => m.CONFIRMATION_INFO_PAGE_ROUTES,
        ),
    },
    {
      path: 'apply-now',
      loadChildren: () =>
        import('./new-pages/confirmation-info-page/routes').then(
          (m) => m.CONFIRMATION_INFO_PAGE_ROUTES,
        ),
    },
    {
      path: 'confirm-details',
      loadChildren: () =>
        import('./new-pages/confirm-personal-details-page/routes').then(
          (mod) => mod.CONFIRM_PERSONAL_DETAILS_PAGE_ROUTES,
        ),
    },
    {
      path: 'offer-page',
      loadChildren: () =>
        import('./new-pages/offer-page/offer-page.module').then(
          (m) => m.OfferPageModule,
        ),
    },
    {
      path: 'programs',
      canActivateChild: [StatusGuard],
      loadChildren: () =>
        import('./new-pages/programs-page/programs-page.module').then(
          (m) => m.ProgramsPageModule,
        ),
    },
    {
      path: 'agreement',
      redirectTo: 'additional-info',
    },
    {
      path: 'references',
      redirectTo: 'additional-info',
    },
    {
      path: 'documents',
      redirectTo: 'additional-info',
    },
    // Stepper Customer Info
    {
      path: 'customer-info',
      loadChildren: () =>
        import(
          './new-pages/stepper-customer-info-page/stepper-customer-info-page.module'
        ).then((m) => m.StepperCustomerInfoPageModule),
    },
    // Stepper Additional Info
    {
      path: 'additional-info',
      loadChildren: () =>
        import(
          './new-pages/stepper-additional-info-page/stepper-additional-info-page.module'
        ).then((m) => m.StepperAdditionalInfoPageModule),
    },
    // Denied by Financial Reasons (After Plaid Report response)
    {
      path: 'app-denied-financial',
      canActivateChild: [StatusGuard],
      loadChildren: () =>
        import(
          './new-pages/denied-financial-page/denied-financial-page.module'
        ).then((m) => m.DeniedFinancialPageModule),
    },
    {
      path: 'account-confirmation',
      loadChildren: () =>
        import(
          './new-pages/account-confirmation-page/account-confirmation-page.module'
        ).then((m) => m.AccountConfirmationPageModule),
    },
    {
      path: 'revenue-verification',
      loadComponent: () =>
        import(
          './new-pages/revenue-verification-page/revenue-verification-page.component'
        ).then((m) => m.RevenueVerificationPageComponent),
    },
    {
      path: 'plaid-report-preparation',
      loadChildren: () =>
        import(
          './new-pages/plaid-report-prep-page/plaid-report-prep-page.module'
        ).then((m) => m.PlaidReportPrepPageModule),
    },
  ],
};

const routesSeoLandingPages: Route = {
  path: '',
  component: DefaultTemplateComponent,
  children: Object.values(SeoLandingPage).map((keyword: string) => ({
    path: `${keyword}/new-lead`,
    loadChildren: () =>
      import('./new-pages/confirmation-info-page/routes').then(
        (c) => {
          return c.CONFIRMATION_INFO_PAGE_ROUTES;
        },
      ),
  })),
};

const MAIN_PAGE = `/apply-now?PID=${environment.production ? '8888' : environment.pid_test }`;

export const routes: Routes = [
  {
    path: '',
    redirectTo: MAIN_PAGE,
    pathMatch: 'full',
  },
  routesDefaultTemplateStaticPages,
  routesFormTemplatePages,
  routesFormTemplateStaticPages,
  // SEO Routes for Landing Pages
  routesSeoLandingPages,
  {
    path: '**',
    redirectTo: MAIN_PAGE,
  },
];
